import React, { useEffect } from 'react'
import { parse } from 'query-string'
import Loadable from 'react-loadable'
import { makeStyles } from '@material-ui/styles'
import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from 'react-apollo'
import QUERY from '../query'
import ItemList from '../containers/ItemList'
// const SEARCH = gql`
//   mutation($message: String!, $type: String!) {
//     search(message: $message, type: $type) @client
//   }
// `
const SEARCH = gql`
  query($message: String!, $user_id: String!) {
    search(message: $message, user_id: $user_id) {
      ...Result
    }
  }
  ${QUERY.SEARCH_RESULT}
`
const ButtonList = Loadable({
  loader: () => import('../containers/ButtonList'),
  loading: () => null,
})

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  buttonList: {
    minWidth: 300 + theme.spacing(1),
    padding: theme.spacing(1, 1),
  },
  buttonGroup: {
    position: 'fixed',
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  hidden: { display: 'none' },
  progress: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
  },
}))
const MESSAGE = gql`
  query {
    search @client {
      message
    }
  }
`
const IndexPage = ({ location: { search } }) => {
  const {
    data: {
      session: { user_id },
    },
  } = useQuery(gql`
    query {
      session @client {
        user_id
      }
    }
  `)
  const classes = useStyles()
  return <ItemList />
}
export default IndexPage
